import { ChargeApi, FileManagerApi, SubscriptionApi } from '@open-api/ump/charge-invoice'

export const chargeApi = new ChargeApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const fileManagerApi = new FileManagerApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})

export const subscriptionApi = new SubscriptionApi({
  basePath: process.env.PRIVATE_UMP_HOST || process.env.NEXT_PUBLIC_UMP_HOST,
})
